/* Dark */
.gradientOverlayDarkTop:before {
  /* top */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  pointer-events: none; /* Ensures the overlay doesn't interfere with other interactions */
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%);
}

@media screen and (min-width: 768px) {
  .gradientOverlayDarkTop:before {
    top: -1rem;
  }
}
.gradientOverlayDarkTop:after {
  /* left */
  content: '';
  position: absolute;
  top: 0;
  left: -0.25rem;
  width: 20%;
  height: 100%;
  pointer-events: none; /* Ensures the overlay doesn't interfere with other interactions */
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 75%);
}

.gradientOverlayDarkBottom:before {
  /* bottom */
  content: '';
  position: absolute;
  bottom: -1rem;
  right: 0;
  width: 100%;
  height: 25%;
  pointer-events: none; /* Ensures the overlay doesn't interfere with other interactions */
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%);
}
.gradientOverlayDarkBottom:after {
  /* right */
  content: '';
  position: absolute;
  bottom: 0;
  right: -0.25rem;
  width: 20%;
  height: 100%;
  pointer-events: none; /* Ensures the overlay doesn't interfere with other interactions */
  background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 75%);
}

/*  Light */
.gradientOverlayLightTop:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20%;
  pointer-events: none; /* Ensures the overlay doesn't interfere with other interactions */
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
@media screen and (min-width: 768px) {
  .gradientOverlayLightTop:before {
    top: 0;
  }
}
.gradientOverlayLightTop:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 100%;
  pointer-events: none; /* Ensures the overlay doesn't interfere with other interactions */
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.gradientOverlayLightBottom:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 20%;
  pointer-events: none; /* Ensures the overlay doesn't interfere with other interactions */
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
.gradientOverlayLightBottom:after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20%;
  height: 100%;
  pointer-events: none; /* Ensures the overlay doesn't interfere with other interactions */
  background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}
